<template>
  <div class="survey">
    <b-form>
      <b-form-group label-cols="3" :label="title">
        <b-row class="form-inner-row">
          <b-col class="form-inner-col">
            <label>{{ q.answer[0].label }}</label>
            <b-form-radio-group v-model="q.answer[0].data">
              <b-form-radio value="전화">전화</b-form-radio>
              <b-form-radio value="방문">방문</b-form-radio>
            </b-form-radio-group>
          </b-col>
          <b-col class="form-inner-col">
            <label>{{ q.answer[1].label }}</label>
            <b-form-input type="text" v-model="q.answer[1].data" placeholder="YYYYMMDD"/>
            <span class="text-danger">* 숫자 8자리</span>
          </b-col>
        </b-row>
      </b-form-group>
    </b-form>
  </div>
</template>
<script>
export default {
  name: 'Q1',
  model: {
    prop: 'q',
  },
  props: ['q'],
  data() {
    return {
      title: '등록방법 및 등록일',
    };
  },
  updated() {
    if (this.q === '') {
      this.q = {
        title: '등록방법 및 등록일',
        answer: [
          { label: '등록방법', data: '' },
          { label: '등록일', data: '' },
        ],
      };
    }
  },
};
</script>
